/* eslint-disable max-len */
/* eslint-disable no-console */
import { MDXProvider } from "@mdx-js/react";
import cx from "classnames";
import { Link, PageProps, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { SEO, SiteLayout, TasFollowButtons, TasShareButtons } from "../components";
import CallUs from "../components/callus";
import ViewMoreFB from "../components/morefblink";
import { ServiceCard } from "../components/servicecard";


// export default function PageTemplate({ data: { mdx } }) {
//     return (
//         <div>
//             <h1>{ mdx.frontmatter.title }</h1>
//             <MDXProvider components={ shortcodes }>
//                 <MDXRenderer frontmatter={ mdx.frontmatter }>{ mdx.body }</MDXRenderer>
//             </MDXProvider>
//         </div>
//     );
// }
// export const pageQuery = graphql`
//   query BlogPostQuery($id: String) {
//     mdx(id: { eq: $id }) {
//       id
//       body
//       frontmatter {
//         title
//       }
//     }
//   }
// `;

/* eslint-disable */
const components = {
  wrapper: ({ children }) => <>{children}</>,
  h1: (props: any) => (
    <h1 style={{ fontSize: "60px", fontWeight: "bold" }} {...props} />
  ),
  h2: (props: any) => (
    <h2 style={{ fontSize: "40px", fontWeight: "bold" }} {...props} />
  ),
  h3: (props: any) => (
    <h3 style={{ fontSize: "30px", fontWeight: "bold" }} {...props} />
  ),
  h4: (props: any) => (
    <h4 style={{ fontSize: "20px", fontWeight: "bold" }} {...props} />
  ),
  h5: (props: any) => (
    <h5 style={{ fontSize: "20px", fontWeight: "bold" }} {...props} />
  ),
  ol: (props: any) => <ol style={{ listStyle: "auto" }} {...props} />,
  ul: (props: any) => <ul style={{ listStyle: "auto" }} {...props} />,
  li: (props: any) => <li {...props} />,
  strong: (props: any) => (
    <strong style={{ fontWeight: "900", lineHeight: 1.6 }} {...props} />
  ),
  Link,
  youtube: (props:any)=>(
    <iframe  {...props}
     
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      scrolling="no" allowTransparency={ true } allowFullScreen={ true }>
    </iframe>
  ),
  imgleft: (props: any) => (
    <div className="float-left p-6">
      <img {...props} className="rounded"/>
    </div>
  ),
  tasstrong: (props: any) => (
    <span className="text-2xl goldtxt" {...props}></span>
  ),
  tastitle: (props: any) => (
    <p className="mt-10 mb-6"><span className="text-2xl font-light border-b text-amber-400 border-gray-600" {...props} /></p>
  ),
  imgpara: (props: any) =>{
    var {src, alt, title} = props;
    var {src, alt, ...rest} = props;
    return (
      <div className="flex flex-row place-items-center md:place-items-end mt-6 mb-6">
          <img className="float-left rounded pl-6 pr-6 pt-6"  src={ src } title={ title } alt={ alt } style={ { maxHeight: "250px" } }/>
          <p class="float-right ml-4" {...rest} />
      </div>
    )
  },
  gallary: (props: any) => {
    
    // console.log(props.data)

    return <div className="grid grid-cols-2 grow md:grid-cols-4 items-stretch mt-10 mb-10 pl-4 pr-4 justify-center place-items-center place-content-center gap-2 w-full">
     {props.data.map((obj: any, index: number) => (
        <div className="w-full p-1" key={ index }>
          <img
            className={ `${obj.class} imgzoom block object-cover object-center w-full h-full rounded-lg` }
            {...obj} />
        </div>
      ))}
    </div>;
  },
}
/*

<gallary data={
  [
    {src:"/img/ppf-basic/boot-setp-ppf.jpg", alt:"Boot step PPF protection"}
  ]
}/>
*/

//  img: (props: any) => <img style={{ width: "100%" }} {...props} />,

const tplobj = {
  tas : "TAS, Travancore Auto Spa, Detailing Studio"
}

function _templateExc(tpl: string, params: any): string {
  const func = new Function(
    ...Object.keys(params),
    "return `" + tpl + "`;"
  );
  return func(...Object.values(params));
}


const PhotoGallary = ({ frontmatter }) => <div className="container mx-auto">
  <div className="grid-cols-3 p-10 space-y-2 lg:space-y-0 lg:grid lg:gap-3 lg:grid-rows-3">

    <div className="w-full col-span-2 row-span-2 rounded">
      <GatsbyImage image={getImage(frontmatter.heroimage.file)}
        alt={frontmatter.heroimage.title} />
    </div>
    {frontmatter.photos.map((obj: any, index: number) => (
      <div className="w-full rounded">
        <GatsbyImage key={index} image={getImage(obj)}
          alt={frontmatter.heroimage.title} />
      </div>
    ))}
  </div>
</div>

const PhotoGallary1 = ({ frontmatter }) => {
  // const {photosmeta}: {photosmeta: string[]} = frontmatter;
  const { photosmeta, photos, heroimage }: {photosmeta: string[],photos:Array<any> , heroimage:any  }  = frontmatter;
  const { title } = heroimage;
  
  const metamap = photosmeta == null ? null : photosmeta.reduce(function(map, strvalue) {
      const vals:string[] = strvalue.split("#");
      map[ vals[0] ] = _templateExc(vals[1] as string, tplobj);
      return map;
  }, {});

  const _getTitle = (filename:string) => metamap == null ? title :  metamap[filename] == null ? title : metamap[filename]

  return <section className="overflow-hidden  mt-10 ">
     <p className="ml-6 mb-2 text-slate-400">
    <i><sup>*</sup>click on image to zoom.</i>
  </p>
  <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-6 items-stretch pl-4 pr-4 justify-center place-items-center gap-2 w-full">

      <div className="flex  w-full">
            <GatsbyImage image={getImage(heroimage.file)} className=" imgzoom block object-cover object-center w-full h-full rounded-lg border border-yellow-600"
              alt={title} />
        </div>
      {photos.map((obj: any, index: number) => (
        <div className="flex w-full">
          <GatsbyImage key={index} image={getImage(obj)} alt={ _getTitle(obj.name) } title={ _getTitle(obj.name) }
            className="imgzoom block object-cover object-center w-full h-full rounded-md border border-yellow-800" />
        </div>
      ))}


    </div>
</section>
};



function toImageGalVal(galval) {
  ///img/crystal-serum-audi.jpgx150#Gtechniq Crystal serum light Audi
  ///img/ceramic_coatings.jpg#TAS nano ceramic coating
  const imgparts0 = galval.split("#");
  const imgparts1 = imgparts0[0].split("x");

  const imgorg = imgparts1[0];
  const imgsize = imgparts1[1];

  const ext = imgorg.split('.').pop();
  const srcloc = imgsize == null ? imgparts0[0] :  imgorg.split("." + ext)[0] + "_thumb" + "_" + imgsize + "." + ext;

  return {
    src: imgorg,
    thumb: srcloc,
    alt: imgparts0[1]
  }
}

const PhotoGallary2 = ({ frontmatter }) => <section className="overflow-hidden text-gray-700 mt-6 mb-6">
  <p className="ml-6 mb-2 text-slate-400">
    <i><sup>*</sup>click on image to zoom.</i>
  </p>
  <div className="flex flex-wrap flex-grow place-content-center place-items-center gap-2">
      {frontmatter.thumbgallery.map((thumb: any, index: number) => (
        <ThumbImg key={index} {...toImageGalVal(thumb)}/>
      ))}
    </div>
</section>;

const ThumbImg = (props:any) =>  
<div><img src={props.thumb} data-src={props.src} title={props.alt} alt={props.alt}
data-sizes="auto" className="imgzoom lazyload rounded border border-yellow-600" style={{ "width": "150px", "cursor": "pointer" }} />
 <p className="text-center text-gray-500 text-xs ml-1 mr-1"  style={{ "width": "150px" }}>{ props.alt }</p>
</div>;

const NextPrevNav = ({ pageContext } ) =>  
<div className="flow-root mt-6 ">
    

    <p className="float-right text-green-800"> 
      <a className="p-2 rounded-lg border border-gray-600 text-right place-items-top flex mr-8 text-gray-500 transition-colors duration-200 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" 
            href={ `/${pageContext.nextnode.slug}` }>
          <div className="flex flex-row flex-grow w-full">
            <GatsbyImage image={getImage(pageContext.nextnode.heroimage.thumb)} 
            className="flex w-12 rounded mr-1" />
            <p className="flex flex-grow"><span>Next<br/> <span className="text-xs">{ pageContext.nextnode.title }</span> </span><span aria-hidden="true" className="ml-2 text-xl font-bold">→</span></p>
          </div>
      </a>
    </p>
   
    <p className="float-left text-green-600">
        <a className="p-2 rounded-lg border border-gray-600 text-left place-items-top flex mr-8 text-gray-500 transition-colors duration-200 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" 
          href={ `/${pageContext.prevnode.slug}` }>
          <div className="flex flex-row flex-grow w-full">
           
          <span aria-hidden="true" className="mr-2 text-xl font-bold" >←</span><span>Previous<br/><span className="text-xs w-min">{ pageContext.prevnode.title }</span></span>
          <GatsbyImage image={getImage(pageContext.prevnode.heroimage.thumb)} 
            className="flex w-12 rounded ml-1" />
          </div>
        </a>
    </p>

</div>;

/**
 * Interface for the Pricing Page props.
 */
//  type IPricingPageProps = TestableComponentInterface;

/**
  * Pricing page of the site.
  *
  * @param {PageProps<IPricingPageProps>} props - Props injected to the component.
  * @return {React.ReactElement}
  */
const ServicePage = (props: any): ReactElement => {

  const {
    data,
    pageContext
  } = props;

  const {
    service,
    site,
    refprods,
    refservice
  } = data;

  const { frontmatter } = service;
  const refsrvs = (refservice ? refservice.edges : null);
  const refprodslist = (refprods ? refprods.edges : null);

  const pageimage = frontmatter.heroimage.file.childImageSharp.gatsbyImageData.images.fallback.src;
  const jsondata = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": pageContext.pagetitle,
    "image": site.siteMetadata.siteUrl + "/" + pageimage,
    "description": frontmatter.description,
    "brand": {
      "@type": "Brand",
      "name": "TAS"
    },
    "offers": {
      "@type": "Offer",
      "url": "",
      "priceCurrency": "INR",
      "price": frontmatter.pricerange ? frontmatter.pricerange : 0,
      "availability": "https://schema.org/InStock",
      "itemCondition": "https://schema.org/NewCondition"
    }
  };
  const pagestyles = cx(`text-xl tracking-wider leading-relaxed mt-6 font-light ${frontmatter.pagestyles}`);

  var priceinfo  = null;
  if(service.frontmatter.pricerange){
    const costs = service.frontmatter.pricerange.split("-");
    priceinfo = (costs.length > 1) ? `₹ ${costs[0]} to  ₹ ${costs[1]}` : `Starting from ₹ ${costs[0]}/-`;
  }
  

  return (
    <SiteLayout data-testid={`service-${frontmatter.uid}-site-layout`} className="text-gray-400 ">
      <Helmet>

        <meta name="title" content={"TAS (Travancore Auto Spa) - Service : " + pageContext.pagetitle} />
        <meta name="description" content={frontmatter.description + " " + frontmatter.hashkeywords} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={site.siteMetadata.siteUrl + "/" + service.fields.slug} />
        <meta property="og:title" content={"TAS (Travancore Auto Spa) - Service : " + pageContext.pagetitle} />
        <meta property="og:description" content={frontmatter.description + " " + frontmatter.hashkeywords} />
        <meta property="og:image" content={site.siteMetadata.siteUrl + "/" + pageimage} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={site.siteMetadata.siteUrl + "/" + service.fields.slug} />
        <meta property="twitter:title" content={"TAS (Travancore Auto Spa) - Service : " + pageContext.pagetitle} />
        <meta property="twitter:description" content={frontmatter.description + " " + frontmatter.hashkeywords} />
        <meta property="twitter:image" content={site.siteMetadata.siteUrl + "/" + frontmatter.image} />

        {pageContext.pagecopies.map((path: string, index: number) => (
          <link rel="canonical" key={index} href={`${site.siteMetadata.siteUrl}/${path}`} />
        ))}

        <script type="application/ld+json">
          {JSON.stringify(jsondata)}
        </script>

      </Helmet>
      <SEO title={pageContext.pagetitle} />
      <TasShareButtons pageContext={ pageContext }/>  
      <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
        <div className="group">
        <div className="flex flex-row grow flex-wrap place-items-center justify-center">
          <div className="grow content-end m-4  justify-center  place-items-center  text-center">
            <p className="group-hover:scale-125 heading text-4xl md:text-6xl font-light font-sans md:leading-tight dark:text-amber-400  transition-all duration-500 ease-in-out transform">
              {frontmatter.title}
            </p>
            <h2 className="text-xl text-gray-400 mt-2 text-center">
              {frontmatter.description}
            </h2>
          </div>
        </div>
        <div className="grow content-end justify-center  place-items-center pb-10">
          <div className="relative max-w-4xl mx-auto px-4">
            <GatsbyImage
              image={getImage(frontmatter.heroimage.file)}
              alt={frontmatter.heroimage.title}
              className="rounded object-cover w-full group-hover:scale-125 transition-all duration-500 ease-in-out transform" />
          </div>
        </div>
        </div>
      </div>
      <div className="py-4">
        <div className="w-full border-t  border-gray-800">
         
        </div>
      </div>
      <div className="relative flex-grow px-4 dark:text-gray-300">
        <CallUs />
      </div>

      <div className="flex flex-col place-items-center justify-center  pb-6">
        
        <div className="grow md:w-4/5 relative mx-auto px-4 text-gray-300 font-light justify-center  place-items-center m-4">
          <div className="flex flex-wrap justify-center place-content-center place-items-center flex-grow gap-2">
            {frontmatter.tags.map((tag: string, i: number) => (
              <span key={i} className="tagbadge">
                {tag}
              </span>
            ))}
          </div>
          {/* <button type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Alternative</button> */}

          <NextPrevNav {...props}/>
          <div className={pagestyles}>
            <MDXProvider components={components}>
              <MDXRenderer frontmatter={service.frontmatter}>{service.body}</MDXRenderer>
            </MDXProvider>
          </div>
          {frontmatter.photos &&
            <div className="flex grow items-stretch">
              <PhotoGallary1 frontmatter={frontmatter} />
            </div>
          }
          {frontmatter.thumbgallery &&
           <section className="thumbgal">
              <PhotoGallary2  frontmatter={frontmatter}/>
            </section>
          }
           {/* recommeded interval */}
           {frontmatter.serviceinterval ? (
                <p className="text-xl ml-4 mt-6">
                    <span className="text-amber-500">Recommended service interval </span>:{" " + frontmatter.serviceinterval}
                </p>
              ) : (
                  ""
                )}

          {priceinfo ? (
                <p className="ml-4 mt-2">
                  <p className="text-xl">
                    <span className="text-amber-500">*Price Range </span>:{" "}
                    {priceinfo}
                  </p>
                  <span className="text-neutral-500 text-sm">
                    <i>
                      * Conditions apply, Tentative pricing only,
                      actual service cost depends on the surface
                      condition, labour, products involved. Please
                      bring the vehicle for FREE inspection and
                      estimation.
                    </i>
                  </span>
                </p>
              ) : (
                  ""
                )}
          {/* hashkeywords */}
          {frontmatter.hashkeywords ? (
            <p className="flex flex-wrap flex-grow justify-center place-content-center place-items-center gap-1 mt-6">
              {
                frontmatter.hashkeywords.split(",").map((tag:string) => <span className="tagbadge">{ tag }</span>)
              }
              
            </p>
          ) : (
            ""
          )}
          {frontmatter.model == "project" &&
            <ViewMoreFB/>
          }
         
        <div className="w-full border-t  border-gray-800 mt-6 mb-6"></div>    
        <NextPrevNav {...props}/> 
        {(refsrvs && refsrvs.length > 0) ?
           <div className="flex  flex-col flex-grow w-full  flex-wrap place-items-start justify-center gap-2 p-4">
              <p className="flex flex-row flex-grow ">
                <span className="underline text-2xl">Related Services</span>
              </p>
              <div className="flex flex-wrap flex-row flex-grow  items-stretch place-items-center gap-4 mt-6">
                {refsrvs.map((obj: any, index: number) => (
                  <ServiceCard data-testid={index} key={index} data={obj.node} />
                ))}
              </div>
            </div>
            : null}     
      

        {(refprodslist && refprodslist.length > 0) ?
            <div className="flex  flex-col flex-grow w-full  flex-wrap place-items-start justify-center gap-2 p-4">
              <p className="flex flex-row flex-grow ">
                <span className="underline text-2xl">Related Products</span>
              </p>
              <div className="flex flex-wrap flex-row flex-grow   items-stretch  place-items-center gap-4 mt-6">
                {refprodslist.map((obj: any, index: number) => (
                  <ServiceCard data-testid={index} key={index} data={obj.node} />
                ))}
              </div>
            </div>
            : null}
        </div>
      </div>
      

          
      <><div className="flex flex-row  w-full  flex-col-1 grow items-stretch flex-wrap place-items-center justify-center gap-2 p-4">
                        <p className="text-center text-xl md:text-2xl xl:text-2xl subpixel-antialiased font-light text-gray-400">
                            Contact us now
                        </p>
                    </div><CallUs />
                    </>
      <TasFollowButtons/>
    </SiteLayout>
  );
};


export default ServicePage;

export const pageQuery = graphql`
query servicesdetails($id: String, $relatedsrvs: [String], $relatedprods: [String]) {
  site: site {
    siteMetadata {
      siteUrl
      title
    }
  }
  service: mdx(id: {eq: $id}) {
    id
    body
    slug
    frontmatter {
      model
      uid
      title
      subtitle
      heroimage {
        title
        file {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      description
      style
      order
      pricerange
      relatedservices
      relatedprods
      tags
      alternatives
      serviceinterval
      hashkeywords
      thumbgallery
      pagestyles
      photosmeta
      photos {
        relativePath
        name
        childImageSharp {
          gatsbyImageData(formats: [WEBP, JPG])
        }
      }
    }
    fields {
      slug
    }
  }
  refservice: allMdx(
    filter: {frontmatter: { uid: {in: $relatedsrvs} }}
  ) {
    edges {
      node {
        id
        slug
        frontmatter {
          model
          title
          description
          heroimage {
            title
            file {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
  refprods: allMdx(filter:{frontmatter:{uid:{in: $relatedprods}}}) {
    edges {
      node {
        id
        slug
        frontmatter {
          model
          title
          description
          heroimage {
            title
            file {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}

 `;


